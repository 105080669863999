<template>
	<div class="titleDash">DashBoard</div>
	<div class="dash">
		<AvisosItem />
		<aside>
			<BoxDatas />
		</aside>
	</div>
</template>

<script>
// import axios from 'axios';
import AvisosItem from '../components/avisos.vue'
import BoxDatas from '../components/box-datas.vue'

export default {
	name: 'IndexMain',
	components: {
		AvisosItem,
		BoxDatas
	},
	data() {
		return {
			nav: true,
			strNav: 'dash',
			check: true,
			info: [],
			modP: false
		}
	},
	methods: {
	},
	mounted(){
	}
}
</script>

<style scoped>

.titleDash {
	width: auto;
	font-size: 28px;
	font-weight: 600;
	color: #444;
	padding: 20px;
	margin: 20px 0;
	text-shadow: 0 1px 4px rgba(0,0,0,.2);
}

.dash {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}

aside {
	width: 28%;
}

</style>
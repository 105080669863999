<template>
	<div class="pageLogin">
		<div class="box">
			<div class="left">
				<div class="icon" :style="$store.state.system.logo != '' ? {backgroundImage: 'url('+$store.state.system.logo+')'} : {}"></div>
			</div>
			<div class="login">
				<!-- <div class="title">Login</div> -->
				<form @submit.prevent="setLogin">
					<template v-if="$store.state.system.mobile">
						<span>Link de acesso:</span>
						<select v-model="$store.state.system.baseURL">
							<option v-for="(d, idx) in base" :key="idx" :value="d.domain" @click="$store.state.system.baseURL = d.domain" :selected="d.domain == $store.state.system.baseURL">{{d.name}}</option>
						</select>
					</template>
					<span>Usuário</span>
					<input type="text" name="user" placeholder="Usuário" v-model="user">
					<span>Senha</span>
					<input type="password" name="pass" placeholder="Senha" v-model="pass">
					<label class="cookie">
						<input type="checkbox" v-model="check">
						<span>&nbsp;Lembrar usuário</span>
					</label>
					<button>LOGIN</button>
				</form>
			</div>
			<span class="version">Version: {{ $store.state.system.version }}</span>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'LoginPage',
	components: {
	},
	data() {
		return {
			user: '',
			pass: '',
			check: false,
			textA: '',
			base: []
		}
	},
	methods: {
		async setLogin() {
			const req = await axios.post('login', {
				user: this.user,
				pass: this.pass,
				check: this.check
			});
			
			this.$store.commit('Alert', req.data.text)
			
			if(req.data.logged){
				this.$store.state.logged = req.data.logged
				this.$store.state.user = {
					id: req.data.user.id,
					user: req.data.user.user,
					image: req.data.user.image
				}
				this.$store.state.system.title = 'Olá, '+req.data.user.user
			}
		},
		async getDom(){
			const req = await axios.get('https://api.pauloferraco.com.br/ppanel/access');
			this.base = req.data
		}
	},
	mounted(){
		if(this.$store.state.system.mobile){
			this.getDom()
		}
	}
}
</script>

<style scoped>
.pageLogin {
	position: fixed;
	width: 100%;
	height: 100%;
	background: var(--color1);
	background: linear-gradient(145deg, var(--color1) 0%, var(--color2) 100%);
	z-index: -2;
}

.pageLogin .box {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 50%;
	height: auto;
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	transform: translate(-50%, -50%);
	z-index: 0;
}

.pageLogin .box::before {
	content: 'Login';
	position: absolute;
	top: -130px;
	left: -10px;
	font-size: 100px;
	font-weight: 600;
	color: #fff;
	opacity: .7;
}

.pageLogin .box .left {
	width: 40%;
	/* height: 100%; */
	background-color: var(--color2);
	border-radius: 10px 0 0 10px;
	/* margin-left: -1px; */
}

.pageLogin .box .left .icon {
	position: relative;
	left: 50%;
	top: 50%;
	width: 60%;
	height: 60%;
	background-image: url('../assets/icon-login.gif');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	transform: translate(-50%, -50%);
}

.pageLogin .box .login {
	width: 60%;
	padding: 80px;
	user-select: none;
}

.pageLogin .box .login .title {
	font-size: 40px;
	color: #333;
	font-weight: 800;
	margin-top: 20px;
}

.box .login form {
	width: 100%;
	display: grid;
	justify-items: center;
}

.box .login span {
	float: left;
	width: 100%;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
	font-weight: 800;
	color: #444;
}

.box .login input {
	width: 100%;
	height: 40px;
	border-radius: 8px;
	border: 1px solid rgba(0,0,0,.2);
	margin: 8px 0 10px 0;
	padding: 20px;
}

.box .login button {
	width: 30%;
	height: 40px;
	background-color: var(--color2);
	border-radius: 40px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	margin-top: 10px;
}

.box .login .cookie {
	width: auto;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.box .login .cookie input {
	position: relative;
	padding: 0;
	width: 16px;
	height: 16px;
	top: 2px;
}

.box > .version {
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: auto;
	font-size: 12px;
	color: #9b9b9b;

}

.box .login .cookie span {width: 99%;margin-top: 4px;font-size: 12px;font-weight: 400;white-space: nowrap;}

@media only screen and (max-width: 900px) {
	.pageLogin .box::before {font-size: 60px;}
	.pageLogin .box {width: calc(100% - 10px);padding-bottom: 10px;}
	.pageLogin .box .left {display: none;}
	.pageLogin .box .login {width: 100%;padding: 10px;}
	.box .login .cookie {width: 41%;}
	.box .login form {margin-top: 30px;}
	.pageLogin .box .login .title {margin-top: 0;}
}

@media only screen and (max-width: 1400px) {
	.pageLogin .box {width: 60%;}
	.pageLogin .box .login {padding: 60px;}
	.pageLogin .box .left .icon {width: 100%;}

}

/* @media only screen and (max-width: 480px) {
	.pageLogin .box {width: calc(100% - 20px);}
	.pageLogin .box .left {display: none;}
	.pageLogin .box .login {width: 100%;padding: 20px;}
} */
</style>
<template>
	<div class="modal">
		<div class="empty" @click="$emit('close')"></div>
		<div class="box">
			<div class="top">
				<div class="title">Configuraçoções</div>
				<div class="close" @click="$emit('close')"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<form @submit.prevent="formEmail">
					<span>Email:</span>
					<input type="email" placeholder="Email" v-model="email">
					<button>Salvar</button>
				</form>
				<form @submit.prevent="formPass">
					<span>Senha:</span>
					<input type="password" placeholder="Nova senha" min="6" max="16" v-model="pass">
					<span>Repetir senha:</span>
					<input type="password" placeholder="Repetir senha" min="6" max="16" v-model="passr">
					<button>Salvar</button>
				</form>
				<form @submit.prevent="formProg">
					<span>Mudar sua programação da rádio:</span>
					<input type="text" placeholder="Sua programação" v-model="prog">
					<button>Salvar</button>
				</form>
				<form class="formRs" @submit.prevent="postRS">
					<span>Redes sociais:</span>
					<input type="text" placeholder="Facebook" v-model="fb">
					<input type="text" placeholder="Twitter" v-model="tt">
					<input type="text" placeholder="Discord" v-model="dc">
					<div class="btn" @click="verRs($event)">Ver mais</div>
					<button>Salvar</button>
				</form>
				<form @submit.prevent="postPhoto">
					<span>Mudar foto:</span>
					<input type="file" accept="image/*" id="file" ref="file" @change="uploadfile()">
					<button>Salvar</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'ConfigUser',
	components: {
	},
	data() {
		return {
			user: [],
			fb: '',
			tt: '',
			dc: '',
			textA: '',
			email: '',
			pass: '',
			passr: '',
			file: '',
			prog: '',
		}
	},
	methods: {
		async formEmail(){
			if(this.email != ''){
				const req = await axios.post('async_util/configUser/email', {
					email: this.email
				})
				this.$store.commit('Alert', req.data.text)
			}else{
				this.sendError('Preencha o campo!');
			}
		},
		async formProg(){
			if(this.prog != ''){
				const req = await axios.post('async_util/configUser/prog', {
					prog: this.prog
				})
				this.$store.commit('Alert', req.data.text)

			}else{
				this.sendError('Preencha o campo!');
			}
		},
		async formPass(){
			if(this.pass != '' || this.passr != ''){
				const req = await axios.post('async_util/configUser/changePass', {
					pass: this.pass,
					passr: this.passr
				})
				this.$store.commit('Alert', req.data.text)

			}else{
				this.sendError('Preencha os campos!');
			}
		},
		sendError(text) {
			this.textA = text

			setTimeout(() => {
				this.textA = ''
			}, 3000);
		},
		verRs(e){
			const form = document.querySelector('.formRs')

			if(e.target.innerHTML == 'Ver mais'){
				form.style.height = '198px'
				form.style.overflow = 'inherit'
				e.target.innerHTML = 'Ver menos'
			}else{
				form.style.height = '110px'
				form.style.overflow = 'hidden'
				e.target.innerHTML = 'Ver mais'
			}
		},
		async postRS(){
			const req = await axios.post('async_util/configUser/changeRS', {
				facebook: this.fb,
				twitter: this.tt,
				discord: this.dc
			})

			this.$store.commit('Alert', req.data.text)

			setTimeout(() => {
				this.textA = ''
			}, 3000);
		},
		uploadfile() {
			this.file = this.$refs.file.files[0];
		},
		async postPhoto(){

			let formData = new FormData();

			formData.append('file', this.file);
			
			const req = await axios.post('async_util/configUser/changePhoto', 
				formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
			})
			this.$store.commit('Alert', req.data.text)
		},
		async getDado(){
			const req = await axios.get('async_util/configUser/get');
			this.email = req.data.email;
			this.prog = req.data.program;
			let rd = JSON.parse(req.data.redes)
			this.fb = rd.facebook
			this.tt = rd.twitter
			this.dc = rd.discord
		}
	},
	mounted(){
		this.getDado();
	}
}
</script>

<style scoped>

.modal .content {
	display: block;
}

.modal form {
	float: left;
	width: 100%;
	display: block;
}
.modal form:last-of-type {padding-bottom: 10px;}

.modal form span {
	float: left;
	width: 100%;
}

.modal form input {
	float: left;
	width: calc(100% - 80px)  !important;
}
.modal form input[type=file] {padding: 0 10px;}

.modal form button {
	float: left;
	margin-left: 10px;
}

.modal form label {
	float: left;
	width: 100%;
	height: auto;
}

.modal form label > div {
	width: 40px;
	height: 40px;
	cursor: pointer;
	background-color: var(--color1);
}

.modal .formRs {
	position: relative;
	height: 110px;
	overflow: hidden;
	border-radius: 0 0 10px 10px;
	transition: all .4s ease;
}
.modal .formRs::-webkit-scrollbar{width: 0px;}

.modal .formRs input {margin-top: 6px;}

.modal .formRs button {margin-top: -86px;}

.modal .formRs .btn {
	position: absolute;
	left: 0;
	bottom: 0px;
	width: calc(100% - 80px);
	float: left;
	height: 20px;
	color: #444;
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	border-radius: 0 0 8px 8px;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
	cursor: pointer;
}

</style>
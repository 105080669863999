<template>
	<div class="notification">
		<div class="box">
			<div class="top">
				<div class="title">Notificações</div>
				<div class="close" @click="$store.state.notif = !$store.state.notif"><i class="fa-solid fa-xmark"></i></div>
			</div>
			<div class="content">
				<div class="item" v-for="(d, i) in data" :key="i">
					<p>{{d.text}}</p>
					<span class="date">Enviado ás {{d.time}}</span>
					<div class="del" @click="del(d.id)"><i class="fa-solid fa-trash icon"></i></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'PopNotification',
	props: {
		data: Array
	},
	data() {
		return {
			textA: ''
		}
	},
	methods: {
		async del(id){
			const req = await axios.post('async_util/notification/del', {
				id: id
			})
			this.$store.commit('Alert', req.data.text)
			this.$emit('reload')
		}
	}
}
</script>

<style scoped>
.notification {
	position: absolute;
	top: 80px;
	right: 10px;
	width: 300px;
	height: auto;
	max-height: 500px;
	overflow-y: auto;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
}

/* .notification scroll */
.notification::-webkit-scrollbar{width: 4px;}
.notification::-webkit-scrollbar-button { display: none; }
.notification::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0); }
.notification::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.3);
  width: 4px;
  border-radius: 4px;
}

.notification .top {
	height: 40px;
	width: 100%;
	float: left;
	border-bottom: 2px solid rgba(0, 0, 0, .1);
	padding: 10px;
}

.notification .top .title {
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	margin-top: 0px;
}

.notification .top .close {
	position: absolute;
	top: 10px;
	right: 14px;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
}

.notification .content {
	float: left;
	width: 100%;
	height: auto;
	padding: 10px;
}

.notification .content .item {
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	padding: 4px 8px;
	border-bottom: 2px solid #ccc;
	font-size: 14px;
	border-radius: 8px;
	transition: all .3s ease;
}
.notification .content .item:last-of-type {border: 0;}
.notification .content .item:hover {background-color: #eee;}

.notification .content .item p {
	padding: 4px 0;
}

.notification .content .item .date {
	float: right;
	width: 100%;
	height: 20px;
	font-size: 13px;
	font-weight: 300;
	font-style: italic;
	text-align: right;
	line-height: 20px;
}

.notification .content .item .del {
	position: absolute;
	top: 30px;
	left: 8px;
	font-size: 14px;
	opacity: 0;
	transition: all .3s ease;
	cursor: pointer;
}
.notification .content .item:hover .del {opacity: 1;}

@media only screen and (max-width: 500px) {
	.notification {
		width: 100%;
		right: 20px;
	}
}
</style>
<template>
	<div class="datas">
		<div class="box" v-show="datas.news > 0">
			<div class="icon"><i class="fa-solid fa-newspaper"></i></div>
			<div class="data">
				<div>
					<span>{{datas.news}} Notícia(s)</span>
				</div>
				<span class="date">nesse mês</span>
			</div>
		</div>
		<div class="box" v-show="datas.arts > 0">
			<div class="icon"><i class="fa-solid fa-palette"></i></div>
			<div class="data">
				<div>
					<span>{{datas.arts}} Arte(s)</span>
				</div>
				<span class="date">nesse mês</span>
			</div>
		</div>
		<div class="box" v-show="datas.users > 0">
			<div class="icon"><i class="fa-solid fa-users"></i></div>
			<div class="data">
				<div>
					<span>{{datas.users}} Usuário(s) registado(s)</span>
				</div>
				<span class="date">nesse mês</span>
			</div>
		</div>
		<div class="box">
			<div class="icon"><i class="fa-solid fa-user-group"></i></div>
			<div class="data">
				<div>
					<span>{{datas.members}} Membro(s) ativo(s)</span>
				</div>
				<span class="date">no total</span>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';


export default {
	name: 'BoxDatas',
	data() {
		return {
			datas: [],
		}
	},
	methods: {
		async getData(){
			const req = await axios.get('async_util/data-index/get')
			this.datas = req.data
		}
	},
	mounted(){
		this.getData()
	}
}
</script>

<style scoped>

.datas {
	width: 100%;
	height: auto;
	border-radius: 12px;
	box-shadow: 0 2px 6px rgba(0,0,0,.15);
	background-color: #fff;
	padding: 20px;
}

.datas .box {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 20px;
}
.datas .box:last-of-type {margin: 0;}

.box .icon {
	position: relative;
	width: 60px;
	height: 60px;
	font-size: 20px;
	text-align: center;
	line-height: 60px;
	user-select: none;
	border: 2px solid  var(--color1);
	border-radius: 50%;
	transition: all 1s ease;
}

.box > div {
	position: relative;
	width: 60%;
	font-size: 15px;
	font-weight: 600;
}

.box > div span i {
	position: absolute;
	margin: 4px;
}
.box > div span i.fa-caret-up {color: #0a8d15;}
.box > div span i.fa-minus {color: #1c81df;}
.box > div span i.fa-caret-down {color: #e72d2d;}

.box .date {
	font-size: 12px;
	font-weight: 300;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App)
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

axios.defaults.baseURL = store.state.system.baseURL;
axios.defaults.headers = {'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': '*', 'Content-Type': 'application/json; charset=utf-8;'}

createApp(App).use(store).use(VueAwesomePaginate).use(router).mount('#app')